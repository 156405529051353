//////////////////////////////////////////////////
// Note: the "export *" will only export the classes marqued with "export" in their definition
//////////////////////////////////////////////////

import "./css/components.scss";

export * from './ComboBox';
export * from './ComboBoxPlus';
export * from './DataGrid';
//export * from './DateTimeInterval'; //require moment
//export * from './DateTime';
export * from './DropdownList';
export * from './Feedback';
export * from './InputEmail';
//export * from './InputNumber';
export * from './Loading';
export * from './NumberInterval';
export * from './RadioGroup';
export * from './ToggleButtons';
//export * from './RichEditor';
export * from './Switch';
export * from './FormView';

export default class Components{
    static version = 1.0;

    static assets = {
    };
}