import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {faYinYang} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from './libs/components/Components';
import { MainView, RouteCtrl} from './views/MainView';
import { Options} from './common/Options';

import "./css/style.scss";
//import "./libs/prolib/css/components.css";
import "./libs/utils/JsExtension";
import {$glVars, Utils} from "./common/common";
import "./common/i18n";

export default class App extends Component {
    constructor(props){
        super(props);

        this.onSignIn = this.onSignIn.bind(this);
        this.onSignOut = this.onSignOut.bind(this);
        this.signOut = this.signOut.bind(this);

        this.state = {};

        $glVars.urlParams = Utils.getUrlVars();
    }

    componentDidMount(){
        window.document.title = Options.appTitle();   
        console.log(Options.appVersion());
        this.recoverCookieSession(); 
    }

    render() {
        let main = <MainView key={0} onSignIn={(result) => this.onSignIn(result, () => $glVars.routeCtrl.goToMyBookCollection())} onSignOut={this.onSignOut} />;
        let loadingFeedback = <Loading webApi={$glVars.webApi} key={1}><FontAwesomeIcon icon={faYinYang} spin/></Loading>;
        let result = [main, loadingFeedback];
        return (result);
    }

    recoverCookieSession(){
        let that = this;
        let callback = function(result){
            if(result.signedIn){
                that.onSignIn(result.data, () => $glVars.routeCtrl.goTo(window.location.search))
            }
            else{
                that.signOut(window.location.search);
            }
        };

        $glVars.webApi.recoverSession($glVars.cookies.getSessionId(''), callback);
    }

    onSignIn(data, goTo){
        $glVars.cookies.setSessionId(data.sid);
        $glVars.signedUser = data;
        this.forceUpdate();
        goTo();
    }

    onSignOut(){
        let that = this;
        let callback = function(result){
            if(result.success){
                that.signOut();
            }
            else{
                $glVars.feedback.showError($glVars.i18n.appName, result.msg);
            }
            
        };
        $glVars.webApi.signOut(callback);
    }

    signOut(route){
        route = route || "";
        $glVars.cookies.setSessionId(0);
        $glVars.signedUser = null;
        this.forceUpdate();

        if(route.length > 0){
            $glVars.routeCtrl.goTo(route);
        }
        else{
            RouteCtrl.instance.goToHome();
        }
    }
}
