import React, { Component } from 'react';

export class LazyLoadingImage extends Component {
    static defaultProps = {
        src: "",
        alt: "",
        className: '',
        style: null
    };
    
    constructor(props){
        super(props);
        
        this.state = {src: ''};

        this.ref = React.createRef();

        this.observer = null;
    }  

    componentDidMount(){
        //this.setState({valid: this.inputRef.current.checkValidity()});
        //console.log(this.props.alt, this.ref.current.getClientRects())
        this.observer = new IntersectionObserver((entries) => {
            if(entries[0].isIntersecting){
                // el is visible
                //console.log(this.props.alt,'visible')
                let src = this.ref.current.getAttribute('src');
                if(src === null){
                    this.ref.current.setAttribute('src', this.props.src);
                    this.setState({src: this.props.src});
                    this.observer.disconnect();
                }
            } else {
                // el is not visible
                //console.log(this.props.alt,'not visible')
            }
        });
        
        this.observer.observe(this.ref.current); // Asynchronous call
    }

    componentDidUpdate(){
        if(this.props.src !== this.state.src){
            this.ref.current.setAttribute('src', this.props.src);
            this.setState({src: this.props.src});
        }
    }

    render() {   
        let main = <img className={this.props.className} style={this.props.style} ref={this.ref} alt={this.props.alt}/>

        return (main);
    }      
}
