import React, { Component } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export class InputEmail extends Component {
    static defaultProps = {
        name: "",
        value: "",
        placeholder: "",
        onChange: null,
        msgInfo: "If you want to add another email address, separate them by commas. Ex: a@a.com, b@b.com",
        msgInvalidEmail: "Invalid email",
        required: false,
        disabled: false
    };
    
    constructor(props){
        super(props);
        
        this.onChange = this.onChange.bind(this);

        this.state = {valid: false};

        this.inputRef = React.createRef();
    }  

    componentDidMount(){
        this.setState({valid: this.inputRef.current.checkValidity()});
    }

    render() {   
        let spreadAttr = {required: this.props.required, disabled: this.props.disabled};
    
        let main = 
            <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control     ref={this.inputRef} type="text" {...spreadAttr}  value={this.props.value} placeholder={this.props.placeholder} onChange={this.onChange} aria-describedby="inputGroupPrepend" 
                                pattern="^([a-zA-Z0-9_.\-])+@(([a-zA-Z0-9\-])+.)+([a-zA-Z0-9]{2,4})+$" 
                                style={{borderTopRightRadius: '.375rem', borderBottomRightRadius: '.375rem'}}    />
                <Form.Control.Feedback type="invalid" className='mt-2'>{this.props.msgInvalidEmail}</Form.Control.Feedback>
            </InputGroup>

        return (main);
    }   

    onChange(event){ 
        let data = {target: {name: this.props.name, value: event.target.value}};        
        this.setState({valid: this.inputRef.current.checkValidity()});
        this.props.onChange(data);
    }       
}
