import React, { Component } from 'react';
import {$glVars, Assets } from '../common/common';
import { LazyLoadingImage } from '../libs/components/LazyLoadingImage';
import { AvailableProductsView, NewReleases } from './BookView';

export class DashboardView extends Component
{
    static defaultProps = {
    };

    render() {
        let main = 
            <div>
                <SawBio/>
                <NewReleases dataProvider={$glVars.data.availableProducts}/>
                <AvailableProductsView dataProvider={$glVars.data.availableProducts}/>
            </div>;
            
        return main;
    }
}

class SawBio extends Component{
    
    render() {
        //<div id="bio" style={{height: "1.5rem"}} ></div>   
        //<img style={{maxWidth: '300px'}} className='m-auto d-block w-100' src={Assets.sawSignature} alt="Samael Aun Weor signature" />
        let main = 
        <>
                 
            <div className='p-3 text-center container' style={{maxWidth: '900px'}}>
                <h2 className='display-5 m-auto lh-base' style={{maxWidth: '650px'}}>{$glVars.i18n.tags.sawBioTitle}</h2>

                <LazyLoadingImage style={{maxWidth: '270px', minWidth: '200px'}} className='float-sm-left mr-0 mr-sm-5' src={Assets.photoSaw8} alt={$glVars.i18n.tags.saw} />

                <div className='p-3 text-left m-3' style={{fontSize: 'large', lineHeight: '2rem'}}>
                    <p>{$glVars.i18n.tags.sawBioP1}</p>
                    <p>{$glVars.i18n.tags.sawBioP2}</p>
                    <p>{$glVars.i18n.tags.sawBioP3}</p>
                    <p>{$glVars.i18n.tags.sawBioP4}{" "}{$glVars.i18n.tags.sawBioP5}</p>
                    <p className='text-center'><LazyLoadingImage className="img-fluid" style={{maxWidth: 260}} src={Assets.sawSignature} alt={$glVars.i18n.tags.saw} /></p>
                </div>
                <div style={{clear: 'both'}}></div>
                <div className='photo-slider'>
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw1} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw2} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw4} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw3} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw7} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw5} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw9} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw11} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw10} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw12} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw13} alt={$glVars.i18n.tags.saw} />
                    <LazyLoadingImage className='photo-slider-item' src={Assets.photoSaw14} alt={$glVars.i18n.tags.saw} />
                </div>
            </div>
        </>;
            
        return (main);
    }
}
