import React, { Component } from 'react';

export class FormView extends Component{
    static defaultProps = {
        children: null,
        orientation: "horizontal",
        showEmptyFields: false,
        emptyFieldDesc: ""
    };
  
    renderChildren() {        
        return React.Children.map(this.props.children, (child, index) => {
            if(child === null){ return null;} 
  
            return React.cloneElement(child, {
                showEmptyFields: this.props.showEmptyFields,
                emptyFieldDesc: this.props.emptyFieldDesc
            });
        });
    }
  
    render(){
        let main = <div className="FormView" style={this.getStyle()}>{this.renderChildren()}</div>;
        return(main);
    }
  
    getStyle(){
        return {display: (this.props.orientation === "vertical" ? "inline" : "flex") };
    }
  }
  
  class FormViewItem extends Component{
    static defaultProps = {
        label: "",
        value: "",
        htmlStr: "",
        alert: "",
        showEmptyFields: false,
        emptyFieldDesc: ""
    };
  
    render(){
        if(this.props.value === null){ return (null); }
        let value = this.props.value.toString();
  
        if((!this.props.showEmptyFields) && (value.length === 0) && (this.props.htmlStr.length === 0)){ return (null); }
  
        //let style = (this.props.alert ? {fontWeight: "bold"} : null);
        
        return(
        <div className="FormViewItem">            
            <span className="Label">{this.props.label}</span>
            {this.props.htmlStr.length > 0 ? 
                <div data-alert={this.props.alert} className="Value" dangerouslySetInnerHTML={{__html: this.props.htmlStr}}/>
                : 
                <div data-alert={this.props.alert} className="Value" >{(value.length > 0 ? value : this.props.emptyFieldDesc)}</div>
            }
        </div>);
    }
  }
  
  FormView.Item = FormViewItem;