import React, { Component } from 'react';
import { Pagination as PaginationBS } from 'react-bootstrap';

export class Pagination extends Component {
    static defaultProps = {
        onChangePage: null,
        pagination: null
    };
      
    renderPagination(){
        let pagination = [];
        
        let count = this.props.pagination.count;
        let pageCount = Math.ceil(count / this.props.pagination.itemPerPage);
        if (pageCount === 1){
            return null;
        }

        pagination.push(<PaginationBS.First key="first" disabled={this.props.pagination.currentPage === 1} onClick={() => this.props.onChangePage(1)} />);
        pagination.push(<PaginationBS.Prev key="prev" disabled={this.props.pagination.currentPage === 1} onClick={() => this.props.onChangePage(this.props.pagination.currentPage-1)}/>);
        if (pageCount < 10){
            for (let p = 1; p <= pageCount; p++){
                this.pushPage(p, pagination);
            }
        }else{
            let p = this.props.pagination.currentPage
            if (p > 1){
                if (p-1 > 1){
                    this.pushPage(1, pagination);
                }
                if (p-2 > 1){
                    pagination.push(<PaginationBS.Ellipsis key={"el"+p} />);
                }
                this.pushPage(p-1, pagination);
            }
            this.pushPage(p, pagination);
            if (p < pageCount){
                this.pushPage(p+1, pagination);
                if (p+1 < pageCount){
                    pagination.push(<PaginationBS.Ellipsis key={"elend"+p} />);
                }
                if (p+2 < pageCount){
                    this.pushPage(pageCount, pagination);
                }
            }
        }
        pagination.push(<PaginationBS.Next key="next" disabled={this.props.pagination.currentPage === pageCount} onClick={() => this.props.onChangePage(this.props.pagination.currentPage+1)}/>);
        pagination.push(<PaginationBS.Last key="last" disabled={this.props.pagination.currentPage === pageCount} onClick={() => this.props.onChangePage(pageCount)}/>);

        return pagination;
    }

    pushPage(p, pagination){
        pagination.push(<PaginationBS.Item key={p} onClick={() => this.props.onChangePage(p)} active={this.props.pagination.currentPage === p}>{p}</PaginationBS.Item>)
    }

    render() {       
        let main = <PaginationBS className='m-auto' style={{display:'flex',justifyContent:'center'}}>{this.renderPagination()}</PaginationBS>
        return (main);
    }
}
